import { CodCollectionAmount } from './CodCollectionAmount';

export const CodDetail = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  collectionType: '',
  codCollectionAmount: CodCollectionAmount,

};
